<template>
	<v-container>
		<v-row justify="center" justify-content="center">
			<v-col v-for="photo in photos" :key="photo.id" :xl="2" :lg="3" :md="4" :sm="6" :cols="10">
				<photo-card :photo="photo" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import PhotoCard from './PhotoCard';

export default {
	props: {
		photos: null
	},
	components: {
		PhotoCard
	}
};
</script>