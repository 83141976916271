<template>
	<v-layout justify-center>
		<v-flex xs12 sm8 md4 class="wrapper">
			<v-icon dark class="large-icon">mdi-cloud-download</v-icon>

			<div class="display-1 white--text header">{{ i18n('digitalDownloads.title') }}</div>
			<div class="body-2 white--text header">{{ i18n('digitalDownloads.accessCodePrompt') }}</div>
			<p/>
			<br/>

			<v-card>
				<v-card-text v-if="!loading">
					<v-form @submit.prevent="search">
						<v-text-field :label="i18n('digitalDownloads.accessCode')" v-model="code" name="code" prepend-icon="code" :error-messages="codeErrors" @keypress="clearErrorMessage" />
					</v-form>
				</v-card-text>

				<v-card-actions v-if="!loading">
					<v-spacer></v-spacer>
					<v-btn @click="search" color="green" dark id="search-button">
						{{ i18n('digitalDownloads.accessCodeGo') }}
						<v-icon right dark>mdi-firework</v-icon>
					</v-btn>
					<v-spacer></v-spacer>
				</v-card-actions>

				<v-progress-circular class="loading" indeterminate v-if="loading"></v-progress-circular>
			</v-card>

			<v-alert
				type="error"
				:value="!!errorMessage"
				transition="scale-transition"
				class="bottom-message">

				{{ errorMessage }}
			</v-alert>
		</v-flex>
	</v-layout>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import axios from 'axios';
const MIN_ACCESS_CODE_LENGTH = 5;

export default {
	data: () => ({
		code: '',
		errorMessage: null,
		loading: false
	}),
	validations: {
		code: { required, minLength: minLength(MIN_ACCESS_CODE_LENGTH) }
	},
	computed: {
		codeErrors() {
			const errors = [];
			if(this.$v && this.$v.code.$dirty) {
				this.$v.code.required || errors.push(this.i18n('digitalDownloads.accessCodeRequired'));
				this.$v.code.minLength || errors.push(this.i18n('digitalDownloads.accessCodeShort'));
			}

			return errors;
		}
	},
	methods: {
		clearErrorMessage() {
			this.errorMessage = null;
		},
		search() {
			this.errorMessage = null;
			this.loading = false;

			this.$v.$touch();
			if(this.$v.$error) {
				return;
			}

			this.loading = true;
			axios.get(`${this.baseUrl}api/v1/digital-deliveries?accessCode=${this.code}`).then((response) => {
					let batchId = response.data.digitalDelivery.batchId;
					this.$router.push({ name: 'downloads', params: { id: batchId } });
				}).catch((error) => {
					this.loading = false;

					this.errorMessage = this.i18n('digitalDownloads.accessCodeMissing');
					try {
						if(error.response && error.response.data && error.response.data.reason) {
							let reason = error.response.data.reason;
							if(reason !== 'No such digital delivery') {
								this.errorMessage = reason;
							}
						}
					} catch(e) {
						// eslint-disable-next-line
						console.error('Failed to find access code: ', e, error);
					}
				});
		}
	}
};
</script>

<style scoped>
	.wrapper {
		text-align: center;
	}

	.large-icon {
		font-size: 8em;
	}

	#search-button {
		min-width: 8em;
	}

	.loading {
		min-height: 8em;
	}

	.bottom-message {
		margin-top: 1em;
	}
</style>