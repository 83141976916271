<template>
	<v-layout justify-center align-center>
		<v-progress-circular indeterminate v-if="loading"></v-progress-circular>
		<v-alert v-else-if="errorMessage" type="error">{{ i18n('digitalDownloads.loadError') }}: {{ errorMessage }}</v-alert>
		<div class="wrapper" v-if="digitalDelivery">
			<v-icon dark class="large-icon d-none d-md-flex">mdi-cloud-download</v-icon>
			<div class="display-1 white--text header">{{ titleDisplay }}</div>
			<subject-grid :photos="digitalDelivery.photos" />

			<template v-if="showZipDownload">
				<v-btn color="secondary" :href="digitalDelivery.zipDownloadUrl" target="_blank" download class="zip-download-button" :x-large="true">
					{{ i18n('digitalDownloads.downloadAll') }}
					<v-icon right dark>mdi-cloud-download</v-icon>
				</v-btn>
				<p/>
			</template>

			<template v-if="copyrightRelease">
				<v-btn @click="downloadCopyrightRelease" target="_blank" download class="zip-download-button" v-if="copyrightRelease">
					{{ i18n('digitalDownloads.copyrightRelease') }}
					<v-icon right dark>mdi-cloud-download</v-icon>
				</v-btn>
				<p/>
			</template>

			<div class="body-2 white--text header" v-if="studioName">{{ i18n('digitalDownloads.broughtToYouBy') }} <em>{{ studioName }}</em></div>
		</div>
	</v-layout>
</template>

<script>
import axios from 'axios';
import SubjectGrid from './SubjectGrid';

export default {
	props: {
		data: null
	},
	data: () => {
		return {
			loading: true,
			errorMessage: null,
			digitalDelivery: null,
			studioName: null,
			copyrightRelease: null
		};
	},
	computed: {
		showZipDownload() {
			return this.digitalDelivery && this.digitalDelivery.photos.length > 1;
		},
		subjectNameFor() {
			if(this.subjectNameDisplay) {
				return ' for ';
			} else {
				return '';
			}
		},
		titleDisplay() {
			if(this.subjectNameDisplay) {
				return this.i18n('digitalDownloads.downloadFor', {
					name: this.subjectNameDisplay
				});
			} else {
				return this.i18n('digitalDownloads.title');
			}
		},
		subjectNameDisplay() {
			let subject = this.digitalDelivery.subject;
			if(subject['First Name'] && subject['Last Name']) {
				return subject['First Name'] + ' ' + subject['Last Name'];
			} else if(subject['First Name']) {
				return subject['First Name'];
			} else if(subject['Last Name']) {
				return subject['Last Name'];
			}

			return '';
		}
	},
	methods: {
		downloadCopyrightRelease() {
			let a = document.createElement('a');
			a.href = 'data:attachment/csv,' +  encodeURIComponent(this.copyrightRelease);
			a.target = '_blank';
			a.download = this.i18n('digitalDownloads.copyrightRelease') + '.txt';

			document.body.appendChild(a);
			a.click();
			$(a).remove();
		}
	},
	mounted() {
		this.loading = true;
		this.errorMessage = null;

		let url = `${this.baseUrl}api/v1/digital-deliveries?batchId=${this.$route.params.id}`;
		if(this.$route.query.notificationId) {
			url += `&notificationId=${this.$route.query.notificationId}`;
		}
		axios.get(url).then((response) => {
			this.digitalDelivery = response.data.digitalDelivery;
			this.data.logo = response.data.branding.logoUrl;
			this.studioName = response.data.branding.name;
			this.copyrightRelease = response.data.copyrightRelease;
			
			this.loading = false;
		}).catch((error) => {
			// eslint-disable-next-line
			console.error(error);

			this.loading = false;
			this.errorMessage = error.message;
		});
	},
	components: {
		SubjectGrid
	}
};
</script>

<style scoped>
	.wrapper {
		display: flex;
		justify-content: center;
		justify-items: center;
		flex-direction: column;
		flex-grow: 1;
		max-width: 100%;
	}

	.header {
		flex-grow: 1;
		text-align: center;
	}

	.large-icon {
		font-size: 8em;
		overflow: hidden;
	}

	.zip-download-button {
		align-self: center;
	}
</style>