import Vue from 'vue';
import Router from 'vue-router';
import AccessCodePage from './access-codes/AccessCodePage';
import DeliveryPage from './delivery/DeliveryPage';
import UnsubscribePage from './unsubscribe/UnsubscribePage';

Vue.use(Router);

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	fallback: false,
	routes: [
		{
			name: 'main',
			path: '/deliveries/',
			component: AccessCodePage
		},
		{
			name: 'downloads',
			path: '/deliveries/:id',
			component: DeliveryPage
		},
		{
			name: 'unsubscribe',
			path: '/deliveries/:id/unsubscribe',
			component: UnsubscribePage
		},
	]
})
