<template>
	<v-layout justify-center align-center>
		<v-progress-circular indeterminate v-if="loading"></v-progress-circular>
		<v-alert v-else-if="errorMessage" type="error">Failed to load delivery: {{ errorMessage }}</v-alert>
		<div class="wrapper" v-else>
			<div class="display-1 white--text header">{{ header }}</div>
			<div class="title white--text header">{{ headerDescription }}</div>

			<p/>
			<v-btn @click="updateSubscription" class="unsubscribe-button" :color="buttonColor" :dark="unsubscribed">
				<v-icon left dark>{{ buttonIcon }}</v-icon>
				{{ buttonText }}
			</v-btn>
		</div>
	</v-layout>
</template>

<script>
import axios from 'axios';

export default {
	props: {
		data: null
	},
	data: () => {
		return {
			loading: true,
			errorMessage: null,
			unsubscribed: false
		};
	},
	computed: {
		buttonColor() {
			if(this.unsubscribed) {
				return 'green';
			} else {
				return '';
			}
		},
		buttonIcon() {
			if(this.unsubscribed) {
				return 'mdi-emoticon-happy-outline';
			} else {
				return 'mdi-emoticon-cry-outline';
			}
		},
		buttonText() {
			if(this.unsubscribed) {
				return 'Subscribe';
			} else {
				return 'Unsubscribe';
			}
		},
		header() {
			if(this.unsubscribed) {
				return 'You are now Unsubscribed';
			} else {
				return 'Sorry to see you go';
			}
		},
		headerDescription() {
			if(this.unsubscribed) {
				return 'You are will not receive any more digital download emails.  If you change your mind click the button below to get started again.';
			} else {
				return 'Please click below to stop receiving emails about your digital downloads';
			}
		}
	},
	methods: {
		updateSubscription() {
			this.loading = true;

			axios.post(`${this.baseUrl}api/v1/unsubscribe`, {
				notificationId: this.$route.params.id,
				unsubscribed: !this.unsubscribed
			}).then((response) => {
				this.unsubscribed = response.data.unsubscribed;
				
				this.loading = false;
			}).catch((error) => {
				// eslint-disable-next-line
				console.error(error);

				this.loading = false;
				this.errorMessage = error.message;
			});
		}
	},
	mounted() {
		this.loading = true;
		this.errorMessage = null;

		axios.get(`${this.baseUrl}api/v1/unsubscribe?notificationId=${this.$route.params.id}`).then((response) => {
			this.unsubscribed = response.data.unsubscribed;
			
			this.loading = false;
		}).catch((error) => {
			// eslint-disable-next-line
			console.error(error);

			this.loading = false;
			this.errorMessage = error.message;
		});
	}
};
</script>

<style scoped>
	.wrapper {
		display: flex;
		justify-content: center;
		justify-items: center;
		flex-direction: column;
		flex-grow: 1;
		max-width: 100%;
	}

	.header {
		flex-grow: 1;
		text-align: center;
	}

	.large-icon {
		font-size: 8em;
	}

	.unsubscribe-button {
		align-self: center;
	}
</style>