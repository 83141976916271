<template>
	<v-card class="photo-card">
		<v-img :src="photo.cdnUrl" :contain="true" style="max-width: 100%" min-height="12em"/>

		<v-card-text class="text--primary">
			<div>{{ i18n('misc.name') }}: {{ this.photo.upload_file_name }}</div>
			<div v-if="photo.width" class="d-none d-md-block">{{ i18n('misc.width') }}: {{ photo.width }}</div>
			<div v-if="photo.height" class="d-none d-md-block">{{ i18n('misc.height') }}: {{ photo.height }}</div>
		</v-card-text>

		<v-card-actions class="photo-card-actions">
			<v-btn :href="photo.downloadUrl" target="_blank" download color="primary" :large="true">
				{{ i18n('misc.download') }}
				<v-icon right dark>mdi-cloud-download</v-icon>
			</v-btn>
		</v-card-actions>

		<v-card-actions class="photo-card-actions">
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<ShareNetwork network="facebook" :url="photo.presigned_get" title="Subject photo" :description="photo.upload_file_name" class="share-network-button">
						<v-btn color="primary" icon outlined v-on="on">
							<v-icon>mdi-facebook</v-icon>
						</v-btn>
					</ShareNetwork>
				</template>

				<span>{{ i18n('digitalDownloads.shareFacebook') }}</span>
			</v-tooltip>

			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<ShareNetwork network="twitter" :url="photo.presigned_get" title="Subject photo" class="share-network-button">
						<v-btn color="primary" icon outlined v-on="on">
							<v-icon>mdi-twitter</v-icon>
						</v-btn>
					</ShareNetwork>
				</template>

				<span>{{ i18n('digitalDownloads.shareTwitter') }}</span>
			</v-tooltip>

			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<ShareNetwork network="email" :url="photo.presigned_get" title="Subject photo" :description="photo.upload_file_name" class="share-network-button">
						<v-btn color="primary" icon outlined v-on="on">
							<v-icon>mdi-email</v-icon>
						</v-btn>
					</ShareNetwork>
				</template>

				<span>{{ i18n('digitalDownloads.shareEmail') }}</span>
			</v-tooltip>
		</v-card-actions>
	</v-card>
</template>

<script>
import filesize from 'filesize';

export default {
	props: {
		photo: null
	},
	computed: {
		fileSize() {
			return filesize(this.photo.upload_file_size);
		}
	}
};
</script>

<style scoped>
	.photo-card {
		text-align: center;
	}

	.photo-card-actions {
		justify-content: center;
	}
	
	.share-network-button {
		margin-left: 0.2em;
		margin-right: 0.2em;
	}
</style>