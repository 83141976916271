<template>
	<v-app>
		<v-app-bar app color="primary" class="main-toolbar">
			<img class="app-logo" alt="PLIC Deliveries" :src="headerLogo">
		</v-app-bar>

		<v-main class="primary">
			<v-container class="app-content" fluid fill-height>
				<transition name="fade" v-if="!loading">
					<router-view :data="data"></router-view>
				</transition>

				<v-layout justify-center align-center v-if="loading">
					<v-progress-circular indeterminate></v-progress-circular>
				</v-layout>
			</v-container>
		</v-main>

		<update-notification />
	</v-app>
</template>

<script>
import UpdateNotification from '../PlicCanvas/vue/common/UpdateNotification';

export default {
	name: 'App',
	data() {
		return {
			loading: false,
			data: {
				logo: null
			}
		};
	},
	computed: {
		headerLogo() {
			if(this.data.logo) {
				return this.data.logo;
			} else {
				return this.logoUrl;
			}
		}
	},
	methods: {
		updateTitle() {
			if(this.$route.name == 'unsubscribe') {
				document.title = 'Unsubscribe from digital deliveries';
			} else {
				document.title = 'Digital Downloads';
			}
		}
	},
	mounted() {
		this.updateTitle();
	},
	components: {
		UpdateNotification
	}
}
</script>

<style>
	html {
		overflow: auto !important;
	}
	html, body, .v-application {
		height: 100%;
		height: 100vh;
		height: -webkit-fill-available;
	}

	.v-application > .v-application--wrap {
		min-height: 100vh !important;
		min-height: -webkit-fill-available !important;
	}

	.v-application .main-toolbar > .v-toolbar__content {
		justify-content: center;
		justify-items: center;
	}

	.v-application .main-toolbar .app-logo {
		max-height: 90%;
	}

	.app-content > .layout:nth-child(2) {
		display: none;
	}
	.fade-enter-active, .fade-leave-active {
		transition-property: opacity;
		transition-duration: 0.3s;
	}

	.fade-enter-active {
		transition-delay: 0.3s;
	}

	.fade-enter, .fade-leave-active {
		opacity: 0
	}
</style>